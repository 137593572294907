'use client';

import { useEffect, useRef } from 'react';
import gsap from 'gsap';

//* HOC's
import { withLanguageContext, withUserContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import Page from '@/components/common/Page';
import Text from '@/components/common/Text';
import Button from '@/components/common/Button';
import Container from '@/components/common/Container';
import CustomLink from '@/components/common/CustomLink';
import FormItem from '@/components/common/Form/FormItem';
import FormInput from '@/components/common/Form/FormInput';
import FormContainer from '@/components/common/Form/FormContainer';

//* Style
import LoginStyle from 'styles/pages/LoginStyle';

const Login = ({ translate, login }) => {
	//! Refs
	const loginRef = useRef();
	const firstTitleRef = useRef();
	const secondTitleRef = useRef();
	const emailInputRef = useRef();
	const passwordInputRef = useRef();
	const forgotTextRef = useRef();
	const formButtonRef = useRef();
	const createAccountRef = useRef();

	//! Anim
	useEffect(() => {
		gsap.to([loginRef.current, firstTitleRef.current, secondTitleRef.current, emailInputRef.current, passwordInputRef.current, forgotTextRef.current, formButtonRef.current, createAccountRef.current], {
			y: 0,
			opacity: 1,
			stagger: 0.1,
			duration: 0.7,
			ease: 'easeOut',
		});
	}, []);

	const onFinish = (values) => {
		return login(values);
	};

	return (
		<Page className={'login'}>
			<LoginStyle>
				<Container row>
					<div className='empty-col col-3 col-ts-2 col-m-8' />

					<div className='login-container col-2 col-ts-4 col-m-8'>
						<Text
							ref={loginRef}
							className={'h4 font-futura-regular text-center anim-element'}
							text={'login'}
						/>

						<Text
							className={'h2-L font-futura-medium login-title text-center anim-element'}
							text={'loginToYour'}
							ref={firstTitleRef}
						/>

						<Text
							className={'h2-L font-futura-regular text-center anim-element'}
							text={'zoyasPantryAccount'}
							ref={secondTitleRef}
						/>

						<FormContainer onFinish={onFinish}>
							<div
								className='form-field anim-element'
								ref={emailInputRef}>
								<FormItem
									name={'email'}
									validationType={'email'}>
									<FormInput placeholder={`${translate('emailAddress')}*`} />
								</FormItem>
							</div>
							<div
								className='form-field anim-element'
								ref={passwordInputRef}>
								<FormItem
									name='password'
									validationType='password'>
									<FormInput
										type='password'
										placeholder={`${translate('password')}*`}
									/>
								</FormItem>
							</div>

							<div
								ref={forgotTextRef}
								className='flex-end-wrapper anim-element'>
								<CustomLink url={config.routes.forgotPassword.path}>
									<Text
										className={'h6 font-futura-medium darkGrey underline'}
										text={'forgot'}
									/>
								</CustomLink>
							</div>

							<div
								ref={formButtonRef}
								className='login-button anim-element'>
								<Button
									type={'submit'}
									text={'login'}
								/>
							</div>
						</FormContainer>

						<div
							ref={createAccountRef}
							className={'create-account-wrapper anim-element'}>
							<CustomLink url={config.routes.register.path}>
								<Text
									className={'h5 font-futura-semibold underline darkGrey'}
									text={'createAnAccount'}
								/>
							</CustomLink>
						</div>
					</div>

					<div className='empty-col col-3 col-ts-2 col-m-8' />
				</Container>
			</LoginStyle>
		</Page>
	);
};

export default withLanguageContext(withUserContext(Login, ['login']), ['translate']);
