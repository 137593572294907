import styled from 'styled-components';

const LoginStyle = styled.div`
	--loginTitleMarginTop: var(--sp4x);
	--formBlockWrapperMTop: var(--sp8x);
	--antFormItemMarginTop: var(--sp6x);
	--loginButtonMarginTop: var(--sp4x);
	--createAccountMarginTop: var(--sp4x);

	margin: var(--sectionDistance) 0;

	.login-title {
		margin-top: var(--loginTitleMarginTop);
	}

	.form-block-wrapper {
		margin-top: var(--formBlockWrapperMTop);
	}

	.form-field {
		&:not(:first-child) {
			margin-top: var(--antFormItemMarginTop);
		}
	}

	.flex-end-wrapper {
		margin-top: var(--sp1x);
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.login-button {
		margin-top: var(--loginButtonMarginTop);

		button,
		a {
			width: 100%;
		}
	}

	.create-account-wrapper {
		margin-top: var(--createAccountMarginTop);

		a {
			display: block;
			width: fit-content;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.anim-element {
		transform: translateY(100%);
		opacity: 0;
	}

	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--loginTitleMarginTop: var(--sp4x);
		--formBlockWrapperMTop: var(--sp8x);
		--antFormItemMarginTop: var(--sp6x);
		--loginButtonMarginTop: var(--sp4x);
		--createAccountMarginTop: var(--sp4x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--loginTitleMarginTop: var(--sp4x);
		--formBlockWrapperMTop: var(--sp8x);
		--antFormItemMarginTop: var(--sp6x);
		--loginButtonMarginTop: var(--sp4x);
		--createAccountMarginTop: var(--sp4x);
	}

	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--loginTitleMarginTop: var(--sp3x);
		--formBlockWrapperMTop: var(--sp5x);
		--antFormItemMarginTop: var(--sp5x);
		--loginButtonMarginTop: var(--sp2-5x);
		--createAccountMarginTop: var(--sp3x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--loginTitleMarginTop: var(--sp3x);
		--formBlockWrapperMTop: var(--sp5x);
		--antFormItemMarginTop: var(--sp5x);
		--loginButtonMarginTop: var(--sp2-5x);
		--createAccountMarginTop: var(--sp3x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--loginTitleMarginTop: var(--sp3x);
		--formBlockWrapperMTop: var(--sp5x);
		--antFormItemMarginTop: var(--sp5x);
		--loginButtonMarginTop: var(--sp2-5x);
		--createAccountMarginTop: var(--sp3x);
	}
`;

export default LoginStyle;
